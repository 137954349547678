import { createStore } from 'vuex';

const store = createStore({
    state() {
        return {
            loginData: [],
        };
    },
    mutations: {
        setLoginData(state, data) {
            state.loginData = data;
        },
    },
});

export default store;