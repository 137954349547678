import { createRouter, createWebHistory } from 'vue-router'
import { api } from '../plugins/axios'
import Swal from 'sweetalert2';

//cek API
const local = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
const token = window.localStorage.getItem('token') ? JSON.parse(window.localStorage.getItem('token')) : null;
let getOrderSet = [];
if (local?.IDOrder) {
  let params = new FormData();
  params.append('idOrder', local?.IDOrder)
  api.post('/order_status', params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  .then(respon => {
    if (respon.data.message == 'Unauthorized!') {
      getOrderSet = []
    } else {
      getOrderSet = respon.data.data.filter((val) => val.Step == 3 && val.ActivityDate != null)
    }
  })
  .catch(error => {
    getOrderSet = []
  });
}

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/login'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const local = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
      if (local == null) {
        next();
      } else {
        next('/step1');
      }
    },
  },
  {
    path: '/step1',
    name: 'Step1',
    component: () => import('../views/step1/index'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const local = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;

      if (local != null) {
        if (getOrderSet.length != 0) {
          next('/step2');
        } else {
          next();
        }
      } else {
        next('/');
      }
    },
  },
  {
    path: '/step1/theme-photo/:IDProduct',
    name: 'theme-photo',
    component: () => import('../views/step1/theme-photo'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const local = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;

      const IDProduct = to.params.IDProduct;
      const order_list = window.localStorage.getItem('order_list') ? JSON.parse(window.localStorage.getItem('order_list')) : null;
      let cekList = order_list != null ? order_list.filter((val) => val.IDProduct == IDProduct && val.isImageUploaded == 'Y') : []

      if (local != null) {
        if (getOrderSet.length != 0) {
          next('/step2');
        } else {
          if (cekList.length != 0) {
            next('/step1')
          } else {
            next()
          }
        }
      } else {
        next('/');
      }
    },
  },
  {
    path: '/step2',
    name: 'Step2',
    component: () => import('../views/step2/index'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const local = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;

      if (local != null) {
        if (getOrderSet.length != 0) {
          next();
        } else {
          next('/step1');
        }
      } else {
        next('/');
      }
    },
  },
  {
    path: '/step2/layout/:IDProduct',
    name: 'layout',
    component: () => import('../views/step2/layout'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      const local = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;

      // const IDProduct = to.params.IDProduct;
      // const order_list = window.localStorage.getItem('order_list') ? JSON.parse(window.localStorage.getItem('order_list')) : null;
      // let cekList = order_list != null ? order_list.filter((val) => val.IDProduct == IDProduct && val.isDesignApprove == 'Y') : []

      if (local != null) {
        if (getOrderSet.length != 0) {
          next()
          // if (cekList.length != 0) {
          //   next('/step2')
          // } else {
          //   next()
          // }
        } else {
          next('/step1');
        }
      } else {
        next('/');
      }
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  const local = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : null;
  const token = window.localStorage.getItem('token') ? JSON.parse(window.localStorage.getItem('token')) : null;
  if (local != null) {
    let params = new FormData();
    params.append('idOrder', local.IDOrder)
    api.post('/order_status', params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(respon => {
        if (respon.data.message == 'Unauthorized!') {
          localStorage.clear();
          next('/');
        } else {
          next();
        }
      })
      .catch(error => {
        localStorage.clear();
        next('/');
      });
  } else {
    next();
  }
});
export default router
