
//user login
export const setUserSession = (user) => {
    window.localStorage.setItem('user', JSON.stringify(user));
};

export const getUserSession = () => {
    const userJSON = window.localStorage.getItem('user');
    return userJSON ? JSON.parse(userJSON) : null;
};

export const clearUserSession = () => {
    window.localStorage.removeItem('user');
};

//token
export const setToken = (token) => {
    window.localStorage.setItem('token', JSON.stringify(token));
};

export const getToken = () => {
    const tokenJSON = window.localStorage.getItem('token');
    return tokenJSON ? JSON.parse(tokenJSON) : null;
};

export const clearToken = () => {
    window.localStorage.removeItem('token');
};

//order status
export const setOrderStatus = (order_status) => {
    window.localStorage.setItem('order_status', JSON.stringify(order_status));
};

export const getOrderStatus = () => {
    const order_statusJSON = window.localStorage.getItem('order_status');
    return order_statusJSON ? JSON.parse(order_statusJSON) : null;
};

export const clearOrderStatus = () => {
    window.localStorage.removeItem('order_status');
};

//order detail
export const setOrderList = (order_list) => {
    window.localStorage.setItem('order_list', JSON.stringify(order_list));
};

export const getOrderList = () => {
    const order_listJSON = window.localStorage.getItem('order_list');
    return order_listJSON ? JSON.parse(order_listJSON) : null;
};

export const clearOrderList = () => {
    window.localStorage.removeItem('order_list');
};
