import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueLazyload from 'vue-lazyload';
import { api } from './plugins/axios'
import store from './plugins/store';
import './index.css';
import LoadingBlock from './components/LoadingBlock.vue';

import {
    setUserSession, getUserSession, clearUserSession,
    setToken, getToken, clearToken,
    setOrderStatus, getOrderStatus, clearOrderStatus,
    setOrderList, getOrderList, clearOrderList
}
    from '@/plugins/sessionUtil';

import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';


const app = createApp(App);

app.config.globalProperties.$api = api;
const toastOption = {
  pauseOnHover: false,
  multiple: false,
  closeOnClick: false,
  closeButton:false,
  autoClose: 3000,
}

app.config.globalProperties.$toast = toast;
app.config.globalProperties.$toastOption = toastOption;

app.config.globalProperties.$setUserSession = setUserSession;
app.config.globalProperties.$getUserSession = getUserSession;
app.config.globalProperties.$clearUserSession = clearUserSession;

app.config.globalProperties.$setToken = setToken;
app.config.globalProperties.$getToken = getToken;
app.config.globalProperties.$clearToken = clearToken;

app.config.globalProperties.$setOrderStatus = setOrderStatus;
app.config.globalProperties.$getOrderStatus = getOrderStatus;
app.config.globalProperties.$clearOrderStatus = clearOrderStatus;

app.config.globalProperties.$setOrderList = setOrderList;
app.config.globalProperties.$getOrderList = getOrderList;
app.config.globalProperties.$clearOrderList = clearOrderList;

app.config.globalProperties.$url_api = 'https://upload.photolab.id';
const loadimage = require('./assets/lazy/loadlazy-200.png')
const errorimage = require('./assets/lazy/errorlazy-200.png')
app.use(VueLazyload, {
  preLoad: 1.3, // Faktor beban pra dalam layar, misalnya 1.3 berarti 30% di luar layar
  error: errorimage, // Gambar yang ditampilkan jika terjadi kesalahan muat
  loading: loadimage, // Gambar yang ditampilkan selama pemuatan
  attempt: 1, // Jumlah upaya untuk memuat gambar
});

app.component('loading-block', LoadingBlock);
app.use(store);
app.use(router).mount('#app');
