<template>
  <router-view/>
</template>
<style>
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

::-webkit-scrollbar-button {
  display: none;
}
</style>


